import React from "react";

const Footer = () => {
 
  return (
    <>
      <footer>
        

        

        <div>
          <h5>Email</h5>
          <a href="mailto: spiderwebtrix2024@gmail.com">spiderwebtrix2024@gmail.com</a>
          
        </div>

        <div>
          <h5>Phone</h5>
          <a href="tel: +918777480279">+918777480279</a>
        </div>

        <p>©COPYRIGHT 2024 WEBTRIX SPIDER. MADE WITH ❤️ BY <b>SAYAN SARKAR</b></p>
      </footer>
      <div className="footer"></div>
    </>
  );
};

export default Footer;
