import React from 'react';
import { Link } from 'react-router-dom';
import {HashLink} from "react-router-hash-link";
import logo from "../assets/logo.png";

const Header = () => {
  return (
    <nav>
        <a href="/" className="logo">
        <img src={logo} alt="Logo" data-cursorpointer={true} />
      </a>
        <main>
          <HashLink to={"/#home"}>Home</HashLink>
          <Link to={"/contact"}>Contact</Link>
          <HashLink to={"/#about"}>About</HashLink>
          <HashLink to={"/#brands"}>Brands</HashLink>
          <Link to={"/services"}>Services</Link>
        </main>
    </nav>
  )
}

export default Header